import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { css, up, styled, thd, Grid, Col, Row } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';

//import Bizline from './images/footer-sponsor-logo-bizline-@2x.jpg';
import Clipsal from './images/ClipsalLogo@2x.png';
//import Commscope from './images/footer-sponsor-logo-commscope-@2x.jpg';
import Evolt from './images/evolt-atom-air-ektor@2x.png';
//import Fantech from './images/footer-sponsor-logo-fantech-@2x.jpg';
//import Ledvance from './images/footer-sponsor-logo-ledvance-@2x.jpg';
//import Legrand from './images/footer-sponsor-logo-legrand-@2x.jpg';
//import Pierlite from './images/footer-sponsor-logo-pierlite-@2x.jpg';
//import Brilliant from './images/footer-sponsor-logo-brilliant-@2x.jpg';
//import Eaton from './images/footer-sponsor-logo-eaton-@2x.jpg';
//import Nhp from './images/footer-sponsor-logo-nhp-@2x.jpg';
//import Sma from './images/footer-sponsor-logo-sma-@2x.jpg';
//import Ventair from './images/footer-sponsor-logo-ventair-@2x.jpg';
//import Molex from './images/footer-sponsor-logo-molex-@2x.jpg';
import Trader from './images/Traderlogo@2x.png';
//import Hager from './images/footer-sponsor-logo-hager-@2x.jpg';

import { withSettings } from 'containers/WebApp/SettingsContext';

const FooterWrapper = styled.footer`
  border-top: 3px solid ${thd('secondary', '#000')};
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 2rem 0 1rem 0;
  ${up(
    'md',
    css`
      flex-direction: row;
      justify-content: space-between;
    `
  )};
  p {
    color: ${thd('text', '#000')};
    font-size: 16px;
  }
  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${up(
      'md',
      css`
        flex-direction: row;
      `
    )};
  }

  li {
    font-size: 16px;
    color: ${thd('text', '#000')};
    text-align: center;
    padding: 5px 0;
    ${up(
      'md',
      css`
        padding: inherit;
        text-align: left;
        border-right: 1px solid ${thd('text', '#000')};
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: ${thd('text', '#000')};
      font-weight: normal;
      text-decoration: none;
      padding: 0.1rem 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  ul.logos {
    padding: 0.1rem 1rem;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    li {
      text-align: center;
      border: 0;
      ${up(
        'md',
        css`
          padding: inherit;
        `
      )};
    }
  }

  li {
    font-size: 16px;
    color: ${thd('text', '#000')};
    text-align: center;
    padding: 5px 0;
    ${up(
      'md',
      css`
        border-right: 1px solid #fff;
        padding: inherit;
        text-align: left;
        border-right: 1px solid ${thd('text', '#000')};
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: ${thd('text', '#000')};
      font-weight: normal;
      text-decoration: none;
      padding: 0.1rem 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .no-border {
    border: 0;
  }
`;

const Footer = ({ siteName, showLogos }) => (
  <FooterWrapper>
    <FooterContainer>
      <Grid justifyContent="center">
        {showLogos && (
          <React.Fragment>
            <Row justifyContent="center">
              <Col xs={12}>
                <ul className="logos">
                  <li>
                    <img src={Clipsal} alt="Clipsal" width={210} />
                  </li>
                  <li>
                    <img src={Evolt} alt="Evolt" width={190} />
                  </li>
                  <li>
                    <img src={Trader} alt="Trader" width={200} />
                  </li>
                </ul>
              </Col>
            </Row>
          </React.Fragment>
        )}
        <Row>
          <Col xs={12}>
            <ul>
              <li>
                <Link to="/terms">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/sitemap">Sitemap</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="text--center">
              <strong>&copy; Rexel Online Advantage 2024</strong>
            </div>
          </Col>
        </Row>
      </Grid>
    </FooterContainer>
  </FooterWrapper>
);

Footer.propTypes = {
  siteName: PropTypes.string,
  showLogos: PropTypes.bool,
};

Footer.defaultProps = {
  siteName: 'Rexel Advantage',
  showLogos: true,
};

export default withSettings(Footer);
